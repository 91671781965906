.App {

  background-color: #ffffff;

}

@media (max-width: 768px) {

  .App {
      margin: 0;
      padding: 0;
  }

}

