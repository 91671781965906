.footer {
    background-color: #4A99D3;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    color: white;
}

.social img {
    margin: 5px;
}

@media (max-width: 768px) {
    .footer {
        font-size: 12px;
    }
}