.home {
     
}

.section1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding: 0px 100px 0px 100px;
}

.services {
    color: black;
    font-weight: bold;
    font-size: 40px;
}

.changing_text {
    color: #4A99D3;
}

.service_description {
    color: black;
    font-size: 15px;
    line-height: 1.6;
    margin-top: 40px;
    margin-bottom: 40px;
}

@media (max-width: 768px) {

    .home {
        padding: 0px 0px 0px 0px; 
    }
    .section1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 20px 0px 0px 0px; 
    }

    .services {
        align-items: center;
        text-align: center;
        font-size: 30px;
    }

    .service_description {
        text-align: center;
        align-items: center;
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .group_image {
        height: 450px;
        width: 300px;
        margin-top: 30px;
    }

}


.section2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    background-color: #4a9ad33c;
    /* background: linear-gradient(#4A99D3,#fff); */
    padding: 10px 100px 10px 100px;
}

.section2_left {
    text-align: left;
}

.clients {
    line-height: 1.5;
}

.our_clients {
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0.2em;
}

.partner_text {
    font-size: 20px;
    letter-spacing: 0.2em;

}

.company_text {
    font-size: 12px;
    letter-spacing: 0.15em;
}

@media (max-width: 768px) {
    .section2 {
        display: flex;
        flex-direction: column;
        padding: 50px 25px 0px 5px;
        align-items: center;
        text-align: center;
    }

    .top_brands {
        align-items: center;
        text-align: center;
    }

    .clients {
        text-align: center;
        align-items: center;
    }

    .section2_right {
        height: 300px;
        width: 530px;
        margin-top: 30px;
    }
}


.section3 {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 20px;
    line-height: 1.4;
    font-weight: 100;
    padding: 50px;
}

.projects {
    font-weight: bold;
    font-size: 21px;
}

.seemore {
    font-weight: 300;
    font-size: 15px;
    margin-top: 20px;
}

.section4 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    background-color: #4a9ad33c;
    /* background: linear-gradient(#4A99D3,#fff); */
    padding: 50px 0px 10px 0px;
}

.how {
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0.1em;
}

.works {
    display:  flex;
    flex-direction: row;
    margin-top: 40px;
    justify-content: space-between;
}

.works_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 40px;
}

.works_heading {
    margin-top: 10px;
    font-size: 13px;
}

.works_description {
    font-size: 11px;
}

@media (max-width: 768px) { 
    .works {
        display: flex;
        flex-direction: column;
    }
}

.section5 {
    height: 60vh;
    padding: 50px 0px 80px 0px;
}

.client_says {
    align-items: center;
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    font-style: italic;
}

.clients_review {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-left: -150px;
}

.img_back {
    background-color: #4a9ad33c;
    align-items: center;
    text-align: center;
    width: 50vw;
    height: 43.2vh;
    margin-top: 80px;
    
}

.imageback {
    margin-top: -85px;
}

.client_review {
    margin-left: -100px;
}

.sign {
    font-weight: bold;
    font-style: italic;
}

@media (max-width: 768px) { 
    .section5 {
        display: flex;
        flex-direction: column;
        padding: 50px 0px 250px 0px;
    }

    .client_says {
        margin-bottom: 20px;
    }

    .img_back {
        width: 600px;
        height: 315px;
        align-items: center;
        text-align: center;
    }

    .imageback {
        height: 400px;
        width: 300px;
        margin: -85px 0px 0px 150px;
    }

    .clients_review {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .client_review {
        align-items: center;
        text-align: center;
        margin-left: 150px;
        font-size: 12px;
    }

    .quotation_image {
        height: 25px;
        width: 25px;
    }


}