/* Header.module.css */

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    /* background-color: #333; */
    color: #4A99D3;
    position: relative;
    padding: 10px 100px 10px 100px;
}

.logo {
    font-size: 24px;
    font-weight: bold;
}

.hamburger {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 20px;
    cursor: pointer;
}

.hamburger div {
    width: 70%;
    height: 3px;
    background-color: black;
}

.hamburger div:nth-child(2) {
    width: 40%;
}

.menu {
    display: flex;
}

.menu_list {
    list-style: none;
    display: flex;
    gap: 20px;
}

.menu_item {
    position: relative;
}

.menu_link {
    color: black;
    text-decoration: none;
    font-size: 18px;
}

@media (max-width: 768px) {

    .header {
        padding: 0px 15px 0px 15px; 
    }
    
    .hamburger {
        display: flex;
    }

    .menu {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 60px; /* Adjust this value to align below the header */
        right: 20px;
        left: 20px; /* Adjust this value to align below the header */
        background-color: #4A99D3;
        color: white;
        font-weight: 400;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        z-index: 1; /* Ensure the menu appears above other content */
    }

    .menu_list {
        flex-direction: column;
        gap: 10px;
        padding: 10px;
    }

    .menu_item {
        text-align: center;
    }

    .menu.show {
        display: flex;
    }
}
